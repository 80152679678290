// COLOR
$color-primary: #B96B85;
$color-secondary: #9791AD;
$color-tertiary: #82C1C6;
$color-cta: #DD7059;
// $color-primary: rgb(109, 78, 160);
// $color-secondary: rgb(102, 45, 145);
// $color-tertiary: rgb(212, 20, 90);

$color-fill-dark: #191919;
$color-fill-light: #f7f7f7;

// $color-text-dark: rgb(39, 8, 14);
$color-text-dark: #222222;
$color-text-light: #ffffff;

// GRID
$grid-width: 100%; // MOBILE
$grid-width: 1140px; // DESKTOP
$gutter-vert: 4.8rem;
$gutter-horz: 9.6rem;
$padding-vert: 3.2rem;
$padding-horz: 3.2rem;
