.header {
  background-color: $color-text-dark;
  padding: 3rem 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &__logo {
    width: 100%;
  }

  &__contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.4rem;
    font-weight: 400;
    color: $color-text-light;
    position: absolute;
    right: 6.5rem;
  }

  &__phone-icon {
    font-size: 2.1rem;
    // margin-right: 0rem;
  }

  &__phone-number {
    font-size: 1.6rem;
    // margin-right: 6.5rem;
    a,
    :active,
    :visited {
      color: $color-text-light;
      text-decoration: none;
    }
  }
}
