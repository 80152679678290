.main-content {
  box-sizing: border-box;
  width: 100%;
  min-height: 80vh;
  background: linear-gradient(
    to right bottom,
    rgba($color-secondary, 0.9),
    rgba($color-tertiary, 0.9),
    rgba($color-primary, 0.9),
    rgba($color-tertiary, 0.9),
    rgba($color-secondary, 0.9)
  );
  max-width: 100%;
  background-size: 400% 400%;
  animation: gradientBg 15s linear infinite;
  /* polygon coordinence path left-top, right-top, right-bottom, left-bottom */
  clip-path: polygon(0 0, 100% 0, 100% 70vh, 0 100%);
  background-color: rgba($color-primary, 0.9);
  padding: 0 1.6rem;
  position: relative;
}
.main-logo {
  width: 90%;
  max-width: 600px;
  animation: moveInLeft 1s ease-in-out;
}
.main-cta {
  text-decoration: none;
}

.services {
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: flex-start;

  &__image {
    background-image: url(../../images/web_dev-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20rem;
    align-self: center;
    margin-bottom: 2rem;
  }

  &__list {
    margin-top: 2rem;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 300;

    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }
}
@media only screen and (min-width: 600px) {
  .main-content {
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
    height: 600px;
  }
}
