h1 {
  font-family: "Palanquin", sans-serif;
  font-size: 4rem;
  font-weight: 700;
}
h2 {
  font-family: "Palanquin", sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: $color-primary;
}
h3 {
  font-size: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
}
p {
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
}

.headline-primary {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin-bottom: 3.2rem;
  padding-top: 0.8rem;
  color: $color-text-light;
  text-shadow: 0px 1px 3px rgba($color-text-dark, 0.25);
  animation: moveInRight 1s 0.5s ease-in-out;
}

.material-symbols-outlined {
  color: $color-primary;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
