.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}
.col-1-1 {
  @include col-base;
}
.col-1-2 {
  @include col-base;
  width: 50%;
}
