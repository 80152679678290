.contact {
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: flex-start;
  min-height: 90vh;

  p {
    font-size: 1.6rem;
    text-align: left;
    color: $color-text-dark;
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 3rem 0;
  width: 100%;

  &__label {
    font-size: 1.6rem;
    font-weight: 500;
    color: $color-text-dark;
  }
  input,
  textarea {
    outline: none;
    border: 0;
    border-bottom: 2px solid rgba($color-secondary, 0.25);
    padding: 2rem 2rem;

    &::placeholder {
      color: #757575;
    }
    &:focus {
      border-bottom-color: $color-primary;
    }
  }
  &__text {
    width: 100%;
    background-color: rgba($color-secondary, 0.1);
    margin-bottom: 2rem;
  }

  &__button {
    align-self: center;
    margin-top: 2rem;
  }
}
