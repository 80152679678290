.u-center-text {
  text-align: center;
}

.u-margin-bottom-nill {
  margin-bottom: 0;
}
.u-margin-bottom-sm {
  margin-bottom: 1.6rem;
}
.u-margin-bottom-md {
  margin-bottom: 6.4rem;
}
.u-margin-bottom-lg {
  margin-bottom: 12.8rem;
}

.u-padding-bottom-nil {
  padding-bottom: 0 !important;
}

.section__image {
  // background-color: $color-secondary;
  width: 12.5rem;
  height: 12.5rem;
  border: 5px solid $color-text-light;
  border-radius: 13rem;
}
